import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import linkedin from '../images/socials/linkedin.svg';
import twitter from '../images/socials/twitter.svg';
import mail from '../images/socials/mail.svg';
import github from '../images/socials/github.svg';
import facebook from '../images/socials/facebook.svg';

const socials = [
  { name: 'mail', link: 'mailto:support@edduus.com', src: mail },
]

/* more socials
  { name: 'linkedin', link: 'https://www.linkedin.com/company/edduus/', src: linkedin },
  { name: 'twitter', link: 'https://twitter.com/edduusworld?lang=en', src: twitter },
  { name: 'facebook', link: 'https://www.facebook.com/EdduusWorld', src: facebook },
*/

const Footer = props => (
  <div className="ourteam-footer" id="connectwithus">
    <div className="ourteam-footer-header">
      <div className="ourteam-footer-header-icons">
        {socials.map(social => (
          <a className="p-1" key={social.name} href={social.link}>
            <img alt={social.name} src={social.src} />
          </a>
        ))}
      </div>
    </div>
    <div className="ourteam-footer-text">
      <p>
        Edduus Pty Ltd. - incorporated in the US and Australia, with offices
        in Perth and Hong Kong<br/>
        support@edduus.com<br/>
        Copyright © Edduus Pty Ltd All Rights Reserved
      </p>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
